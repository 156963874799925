import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import Layout from '../components/Layout';

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 6em 0 3em 0;
    position: relative;
    &:after {
        content: '';
        display: block;
        height: 3em;
        width: 1px;
        background-color: black;
        position: absolute;
        top: -4.5em;
        left: 50%;
        z-index: 2;
    }
`;

const TextWrapper = styled.div`
    width: 35em;
    padding: 1em;
`;

const Header = styled.h2`
    width: 100%;
    text-align: center;
    font-weight: 100;
    font-size: 2em;
    margin-bottom: 1em;
    text-transform: uppercase;
`;

const Paragraph = styled.p`
    text-align: center;
    font-size: 1.5em;
`;

const Image = styled.div`
    height: 50vh;
    background-image: url(${props => props.image});
    background-size: cover;
    background-position: center;

    @media only screen and (min-width: 993px) {
        height: 60vw;
    }
`;

export const AboutPageTemplate = ({ sections }) => {
    return (
        <div>
            {sections.map(({ title, image, description }) => (
                <>
                    <Wrapper>
                        <TextWrapper>
                            <Header>{title}</Header>
                            <Paragraph>{description}</Paragraph>
                        </TextWrapper>
                    </Wrapper>
                    <Image image={image} />
                </>
            ))}
        </div>
    );
};

AboutPageTemplate.propTypes = {
    sections: PropTypes.shape({
        additionalTitle: PropTypes.string,
        description: PropTypes.string,
        map: PropTypes.func,
        title: PropTypes.string,
    }),
};

const AboutPage = ({ data }) => {
    const {
        markdownRemark: {
            frontmatter: { sections },
        },
    } = data;

    const mappedSections = sections.map(({ image, ...rest }) => {
        return {
            ...rest,
            image: image.childImageSharp.fluid.src,
        };
    });

    return (
        <Layout>
            <AboutPageTemplate sections={mappedSections} />
        </Layout>
    );
};

AboutPage.propTypes = {
    data: PropTypes.object.isRequired,
};

export default AboutPage;

export const aboutPageQuery = graphql`
    query AboutPage($id: String!) {
        markdownRemark(id: { eq: $id }) {
            html
            frontmatter {
                title
                images {
                    image {
                        childImageSharp {
                            fluid(maxWidth: 2048, quality: 64) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                sections {
                    title
                    description
                    image {
                        childImageSharp {
                            fluid(maxWidth: 2048, quality: 64) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    }
`;
